













import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import NumberField from '@/shared/resources/components/forms/fields/NumberField.vue';
import CartItemCoreModel from '@/modules/carts/models/CartItemCoreModel';
import Cart from '@/app/lib/cart/Cart';
import ProductQuantityField from '@/app/modules/products/components/partials/ProductQuantityField.vue';

@Component({
  components: {
    ProductQuantityField,
    NumberField,
  },
})
export default class CartItemsTableQuantityField extends Vue {
  /**
   * Props
   */
  @Prop() private item!: CartItemCoreModel;

  /**
   * Data
   */
  private quantity: any = this.item.quantity;
  private loading: boolean = false;
  private errorMessage: string | null = null;
  private errorTimeoutTime: number = 10 * 1000;
  private timeout: any = null;

  /**
   * Getters
   */
  private get itemQuantity(): number {
    return this.item.quantity;
  }

  private get disabled(): boolean {
    return this.loading;
  }

  private get maxQuantity(): number {
    return this.item.product.amount;
  }

  protected created() {
    if (this.item.product.isStockable && this.quantity > this.item.product.amount) {
      this.errorMessage = 'Przekroczono stan produktu. Max. liczba: ' + this.item.product.amount;
    }
  }

  /**
   * Methods
   */
  private async updateCartItemQuantity(quantity: number) {
    this.loading = true;
    this.errorMessage = null;

    try {
      await new Promise((resolve: any) => setTimeout(resolve, 1000));
      await Cart.updateItemQuantity(this.item, quantity);
    } catch (ex) {
      this.errorMessage = ex.message || 'Wystąpił nieznany błąd';
      this.quantity = this.item.quantity;

      setTimeout(() => {
        this.errorMessage = null;
      }, this.errorTimeoutTime);
    }

    this.loading = false;
  }

  private resetQuantity() {
    this.quantity = this.item.quantity;
  }

  /**
   * Handlers
   */
  private onFieldInput(quantity: any) {
    this.quantity = quantity;

    if (parseInt(quantity, 10) === 0) {
      return;
    }

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.updateCartItemQuantity(quantity);
    }, 500);
  }

  private onFieldEnterPress() {
    this.updateCartItemQuantity(this.quantity);
  }

  private onFieldBlur() {
    if (parseInt(this.quantity, 10) === 0) {
      this.resetQuantity();
    }
  }

  /**
   * Watchers
   */
  @Watch('itemQuantity')
  private watchItemQuantityChange(value: number) {
    this.quantity = value;
  }
}
